<template>
  <div
    class="relative min-h-78 max-h-[80vh] space-y-9"
    data-cy="stips-container"
  >
    <!-- Header -->
    <div class="flex flex-col space-y-5">
      <div
        class="flex justify-around gap-3 md:grid md:grid-cols-2 lg:grid-cols-3 pb-4 sticky top-0 bg-white z-1"
      >
        <lf-h1>{{ $t("DEALS.DEAL_PROGRESS.EDIT_REQ_STIPS") }}</lf-h1>
        <div class="lg:col-span-2 flex flex-shrink-0 justify-end space-x-2">
          <outline-button
            :disabled="!selectedStipsIds.length"
            data-cy="unsellect-all-stips"
            @click="selectedStipsIds = []"
          >
            {{ `${$t("COMMON.UNSELECT")}  ${$t("COMMON.ALL")}` }}
          </outline-button>
          <lf-button
            v-if="canAddStips"
            :disabled="isLoading"
            class="bg-primary bg-opacity-10 font-semibold text-primary"
            data-cy="createNewStipButton"
            @click="onNewStipClick"
          >
            {{ $t("STIPS.NEW_STIP") }}
          </lf-button>
        </div>
      </div>
      <search-input
        class="h-11"
        no-margin
        no-padding
        :model-value="search"
        @update:model-value="updateSearch"
      />
    </div>

    <!-- Stips -->
    <loader :is-loading="isLoading" />
    <div
      ref="stipsContainer"
      class="stips-list-wrapper overflow-y-auto min-h-78"
      data-cy="stip-list"
    >
      <stip
        v-for="(stip, index) in stipsData.data"
        :key="stip.id || index"
        class="max-h-13"
        :stip="{ ...stip, description: stipDescriptions[stip.id] || '' }"
        v-model="selectedStipsIds"
        @update:stip="stipDescriptions[stip.id] = $event.description"
      />
      <div class="relative">
        <form
          v-if="isCreatingStip"
          class="bg-white border border-divider rounded p-3 max-h-58 absolute w-full"
          @submit="onSubmit"
        >
          <div class="flex justify-between w-full mb-3 px-1 flex-1">
            <button
              class="text-gray-400 font-medium cursor-pointer focus:outline-none"
              @click="isCreatingStip = false"
            >
              {{ $t("COMMON.CANCEL") }}
            </button>
            <button
              type="submit"
              class="text-primary font-semibold cursor-pointer focus:outline-none"
            >
              {{ $t("COMMON.SAVE") }}
            </button>
          </div>
          <lf-input
            :placeholder="$t('STIPS.STIP_NAME')"
            :value="newStip.name"
            name="name"
            type="text"
            class="text-xs"
            no-margin
            ref="newStipNameRef"
          />
          <lf-input
            :placeholder="$t('STIPS.ADD_DESCRIPTION')"
            name="description"
            :value="newStip.description"
            no-margin
            class="mt-1-5"
            :maxlength="235"
          />
          <stip-dropdown-categories
            name="file_type"
            class="mt-3"
            :placeholder="$t('STIPS.SELECT_FILE_TYPE')"
            dropdown-teleported
            v-model="selectedDocumentType"
          />
        </form>
        <button
          v-else-if="canAddStips"
          class="focus:outline-none hover:bg-gray-50 flex space-x-3 p-3 justify-center items-center border border-divider rounded h-13 w-full"
          @click="onNewStipClick"
        >
          <span class="font-semibold">
            {{ $t("DEALS.DEAL_PROGRESS.ADD_NEW_STIP") }}
          </span>
          <icon-base :icon="IconPlusSmall" class="text-secondary" />
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { computed, nextTick, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useField, useForm } from "vee-validate";
import { useAuth } from "@/hooks/auth";
import { useActiveWorkflowTemplateStages } from "@/hooks/workflow";
import { useDealsBase } from "@/hooks/deals";
import { useInfiniteScroll } from "@vueuse/core";
import uniq from "lodash/uniq";

import IconBase from "@/components/ui/IconBase.vue";
import IconPlusSmall from "@/components/icons/IconPlusSmall.vue";
import LfButton from "@/components/ui/buttons/LfButton.vue";
import LfInput from "@/components/ui/inputs/LfInput.vue";
import Loader from "@/components/ui/Loader.vue";
import OutlineButton from "@/components/ui/buttons/OutlineButton.vue";
import SearchInput from "@/components/ui/inputs/SearchInput.vue";
import StipDropdownCategories from "@/components/deals/StipDropdownCategories.vue";

import type { IRequiredStipForOffer, IStip } from "@/models/applications";
import type { IStipsState } from "@/models/stips";
import type { FinProductNum } from "@/models/common";
import type { ProductType } from "@/models/options";
import { usePromiseWrapper } from "@/hooks/common";
import Stip from "@/views/deals/components/Stip.vue";

type StipDescriptions = Record<number, IRequiredStipForOffer["description"]>;
type StipFileTypes = Record<number, IRequiredStipForOffer["file_type"]>;

const props = defineProps({
  requiredStips: {
    type: Array as PropType<Array<IRequiredStipForOffer> | Array<IStip>>,
    default: () => []
  },
  preselectFromWorkflowTemplate: {
    type: Boolean,
    default: false
  },
  productTypes: {
    type: Array as PropType<(FinProductNum | ProductType)[]>,
    default: () => []
  },
  requiredStipsFirst: {
    type: Boolean,
    default: false
  }
});

const selectedDocumentType = ref();

const { getters, dispatch } = useStore();
const { activeDeal } = useDealsBase();
const { isAdmin, isClientAdmin, isClientUnderwriter } = useAuth();
const { activeTemplateStages } = useActiveWorkflowTemplateStages();

const stipsContainer = ref<HTMLDivElement | null>(null);
const stipsPage = ref(1);
const search = ref("");
const newStipNameRef = ref<InstanceType<typeof LfInput> | null>(null);
const newStip = reactive({
  name: "",
  description: ""
});
const isCreatingStip = ref(false);

const isLoading = computed<boolean>(() => getters["stips/isLoading"]);
const stipsData = computed<IStipsState>(() => getters["stips/stipsData"]);

const getStipId = (stip: IRequiredStipForOffer | IStip) =>
  stip.stip_id ?? stip.id;

const selectedStipsIds = ref(
  props.requiredStips.map((stip) => getStipId(stip))
);

const stipDescriptions = reactive<StipDescriptions>({});
props.requiredStips.forEach(
  (stip) => (stipDescriptions[getStipId(stip)] = stip.description)
);

const stipFileTypes = computed<StipFileTypes>(() => {
  const result: StipFileTypes = {};
  props.requiredStips.forEach(
    (stip) => (result[getStipId(stip)] = stip.file_type)
  );
  return result;
});

const canAddStips = computed(
  () => isAdmin || isClientAdmin || isClientUnderwriter
);

const { handleChange, value: selectedStips } = useField("stips", undefined, {
  initialValue: props.requiredStips.map((stip) => {
    return {
      id: getStipId(stip),
      description: stip.description ?? null,
      file_type: stip.file_type ?? null
    };
  })
});

const updateStips = () => {
  handleChange(
    selectedStipsIds.value.map((id) => {
      return {
        id,
        description: stipDescriptions[id] || null,
        file_type: stipFileTypes.value[id] ?? null
      };
    })
  );
};

const { handleSubmit } = useForm({
  initialValues: newStip
});

const onSubmit = handleSubmit(async (values) => {
  const newStipId: number = await dispatch("stips/createStip", {
    name: values.name,
    description: values.description,
    file_type: selectedDocumentType.value
  }).then((res) => res.id);

  selectedStipsIds.value.push(newStipId);
  stipDescriptions[newStipId] = values.description ?? null;
  isCreatingStip.value = false;
});

const externalOnSubmit = async () => {
  if (isCreatingStip.value) {
    await onSubmit();
  }
  return selectedStips.value;
};

const onNewStipClick = () => {
  isCreatingStip.value = true;
  nextTick(() => {
    newStipNameRef.value?.$el?.querySelector("input")?.focus();
  });
};

const { fetchWrapper: getStipsList } = usePromiseWrapper(
  async (append: boolean = true) => {
    await dispatch("stips/getStips", {
      append,
      params: { page: stipsPage.value, search: search.value }
    });
    stipsPage.value++;
  }
);

const updateSearch = (value: string) => {
  search.value = value;
  stipsPage.value = 1;
  getStipsList(false);
};

useInfiniteScroll(stipsContainer, async () => await getStipsList(), {
  distance: 100,
  canLoadMore: () => {
    const { meta } = stipsData.value;
    return !isLoading.value && meta.current_page < meta.last_page;
  }
});

watch([selectedStipsIds, stipDescriptions, stipFileTypes], updateStips, {
  immediate: true
});

watch(
  () => props.productTypes,
  () => {
    stipsPage.value = 1;
    search.value = "";
    getStipsList();
  },
  { deep: true }
);

onMounted(async () => {
  if (props.preselectFromWorkflowTemplate || props.requiredStipsFirst) {
    const offerStages = activeTemplateStages.value
      .filter((stage) => stage.type === "offer")
      .map((stage) => stage.id);
    const requiredStipIdsFromTemplate: IStip[] = await dispatch(
      "stips/getStips",
      {
        params: {
          application_id: activeDeal.value.id,
          product_types: props.productTypes,
          workflow_stage_ids: offerStages
        }
      }
    );
    if (props.preselectFromWorkflowTemplate) {
      selectedStipsIds.value = uniq([
        ...selectedStipsIds.value,
        ...requiredStipIdsFromTemplate.map((stip) => stip.id)
      ]);
    }
  }
  getStipsList();
});

defineExpose({ externalOnSubmit });
</script>

<style scoped>
.stips-list-wrapper {
  @apply justify-around gap-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 relative;
  max-height: calc(70vh - 200px);
}
</style>
